<template>
  <div>
    <b-tabs
      pills
      :vertical="$store.getters['app/currentBreakPoint'] === 'xs'"
      content-class="col-12 px-sm-0"
      nav-wrapper-class="col-12 px-sm-0"
      nav-class="nav-left mb-sm-2"
    >
      <b-tab v-for="tab in tabs" :key="tab.key" :title="tab.name" lazy>
        <component
          :is="tab.component"
          :ref="tab.ref"
          @clickView="clickDynamic"
          @clickApprove="row => clickDynamic(row, 'refModalApproveWithdraw')"
          @clickReject="row => clickDynamic(row, 'refModalRejectWithdraw')"
        />
      </b-tab>
    </b-tabs>

    <b-modal
      ref="refModalApproveWithdraw"
      title="อนุมัติการถอนพร้อมแนบหลักฐาน"
      hide-header-close
      ok-title="ยืนยัน"
      cancel-title="ยกเลิก"
      size="lg"
      @cancel="form = {}"
      @ok="confirmWithdraw"
    >
      <dl class="row">
        <dt class="col-sm-5 my-auto">
          ชื่อร้าน
        </dt>
        <dd class="col-sm-7">
          <span>
            {{ form.user_as_shop_name }}
          </span>
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-5 my-auto">
          โอนเข้าบัญชี
        </dt>
        <dd class="col-sm-7">
          <span>
            {{ form.bank_account_data_as_bank_type }} {{ form.bank_account_data_as_bank_no }} ({{
              form.bank_account_data_as_account
            }})
          </span>
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-5 ">
          ยอดสุทธิ
          <small class="text-muted">
            (หัก ณ ที่จ่าย + ค่าธรรมเนียมถอน)
          </small>
          <small class="text-muted d-block">*ยอดที่ครีเอเตอร์จะได้รับเข้าบัญชี</small>
        </dt>
        <dd class="col-sm-7">
          <span class="text-success d-block">
            {{ gFormatNumberToCurrency(form.transaction_data_as_withdraw_amount || 0) }} บาท
          </span>
        </dd>
      </dl>
      <hr />
      <b-form-file
        ref="refInputUploadFile"
        multiple
        placeholder="อัปโหลดหลักฐานการโอน"
        accept="image/jpeg, image/png"
        @input="onUploadFile"
      />
      <div class="mt-1">
        <!-- <b-img v-if="form.file" :src="form.file.preview" fluid alt="Responsive image" />
        <p v-else class="text-center text-muted">โปรดอัปโหลดหลักฐานการโอน</p> -->
        <div class="d-flex flex-wrap">
          <div v-for="slipFile in form.slipImages" :key="slipFile.localId" class="m-50 position-relative">
            <b-img :src="slipFile.preview" :alt="slipFile.localId" width="100" />
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              size="sm"
              class="btn-icon rounded-circle position-absolute"
              style="right: -10px; top: -10px; padding: 0.4rem;"
              @click="removeFile(slipFile)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </div>
        </div>

        <!-- <pre>{{ form }}</pre> -->
      </div>
    </b-modal>

    <b-modal
      ref="refModalRejectWithdraw"
      title="ไม่อนุมัติการถอนพร้อมรายละเอียด"
      hide-header-close
      size="lg"
      ok-title="ยืนยัน"
      cancel-title="ยกเลิก"
      @cancel="form = {}"
      @ok="rejectWithdraw"
    >
      <dl class="row">
        <dt class="col-sm-5 my-auto">
          ชื่อร้าน
        </dt>
        <dd class="col-sm-7">
          <span>
            {{ form.user_as_shop_name }}
          </span>
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-5 my-auto">
          โอนเข้าบัญชี
        </dt>
        <dd class="col-sm-7">
          <span>
            {{ form.bank_account_data_as_bank_type }} {{ form.bank_account_data_as_bank_no }} ({{
              form.bank_account_data_as_account
            }})
          </span>
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-5 ">
          ยอดสุทธิ
          <small class="text-muted">
            (หัก ณ ที่จ่าย + ค่าธรรมเนียมถอน)
          </small>
          <small class="text-muted d-block">*ยอดที่ครีเอเตอร์จะได้รับเข้าบัญชี</small>
        </dt>
        <dd class="col-sm-7">
          <span class="text-success d-block">
            {{ gFormatNumberToCurrency(form.transaction_data_as_withdraw_amount || 0) }} บาท
          </span>
        </dd>
      </dl>
      <hr />
      <b>ระบุเหตุผลที่ไม่อนุมัติ</b>
      <b-form-textarea v-model="form.note" rows="3" class="my-1" />
    </b-modal>

    <b-modal ref="refModalDetailsWithdraw" title="รายละเอียด" hide-footer size="lg" @hide="form = {}">
      <dl class="row">
        <dt class="col-sm-5 my-auto">
          ชื่อร้าน
        </dt>
        <dd class="col-sm-7">
          <span>
            {{ form.user_as_shop_name }}
          </span>
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-5 my-auto">
          เบอร์โทรศัพท์
        </dt>
        <dd class="col-sm-7">
          <span>
            {{ form.user_profile_data_as_phone_number }}
          </span>
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-5 my-auto">
          อีเมล
        </dt>
        <dd class="col-sm-7">
          <span>
            {{ form.email }}
          </span>
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-5 my-auto">
          โอนเข้าบัญชี
        </dt>
        <dd class="col-sm-7">
          <span>
            {{ form.bank_account_data_as_bank_type }} {{ form.bank_account_data_as_bank_no }} ({{
              form.bank_account_data_as_account
            }})
          </span>
        </dd>
      </dl>
      <hr />
      <dl class="row">
        <dt class="col-sm-5 my-auto">ยอดที่ถอนได้</dt>
        <dd class="col-sm-7">
          <span> {{ gFormatNumberToCurrency(form.transaction_data_as_balance || 0) }} บาท </span>
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-5 my-auto">
          จำนวนเงินที่ต้องการถอน
        </dt>
        <dd class="col-sm-7 text-success">
          <span> {{ gFormatNumberToCurrency(form.transaction_data_as_amount || 0) }} บาท </span>
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-5 my-auto">
          หัก ณ ที่จ่าย ({{ Math.round(Number(form.transaction_data_as_withholding)) }}%)
        </dt>
        <dd class="col-sm-7">
          <span class=" text-danger">
            {{
              gFormatNumberToCurrency(
                Number(form.transaction_data_as_amount) * (Number(form.transaction_data_as_withholding) / 100),
              )
            }}
            บาท
          </span>
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-5 my-auto">
          ค่าธรรมเนียมในการถอน
        </dt>
        <dd class="col-sm-7">
          <span class="text-danger">
            {{ gFormatNumberToCurrency(form.transaction_data_as_withdrawl_fee || 0) }} บาท
          </span>
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-5 ">
          ยอดสุทธิ
          <small class="text-muted">
            (หัก ณ ที่จ่าย + ค่าธรรมเนียมถอน)
          </small>
          <small class="text-muted d-block">*ยอดที่ครีเอเตอร์จะได้รับเข้าบัญชี</small>
        </dt>
        <dd class="col-sm-7">
          <span class="text-success d-block">
            {{ gFormatNumberToCurrency(form.transaction_data_as_withdraw_amount || 0) }} บาท
          </span>
        </dd>
      </dl>
      <dl
        v-if="form.transaction_data_as_status_text === 'approve' || form.transaction_data_as_status_text === 'approved'"
        class="row"
      >
        <dt class="col-sm-5">
          ยอดเงินคงเหลือ
        </dt>
        <dd class="col-sm-7">
          <span> {{ gFormatNumberToCurrency(form.transaction_data_as_total || 0) }} บาท </span>
        </dd>
      </dl>

      <hr
        v-if="form.transaction_data_as_status_text === 'approve' || form.transaction_data_as_status_text === 'approved'"
      />
      <div
        v-if="form.transaction_data_as_status_text === 'approve' || form.transaction_data_as_status_text === 'approved'"
        class="mt-1"
      >
        <b class="mb-1">หลักฐานการโอน</b>
        <div v-for="(img, index) in form.image_datas" :key="index">
          <b-img
            :src="img.outside_url"
            alt=""
            width="100"
            class="d-block mx-auto my-1 ct-cursor-zoom"
            @click="gZoomImage"
          />
        </div>
      </div>
      <div
        v-if="form.transaction_data_as_status_text === 'reject' || form.transaction_data_as_status_text === 'rejected'"
      >
        <hr />
        <dl class="row">
          <dt class="col-sm-5 text-danger">
            สาเหตุที่ไม่อนุมัติ
          </dt>
          <dd class="col-sm-7">
            <span>
              {{ form.transaction_data_as_note }}
            </span>
          </dd>
        </dl>
      </div>

      <!-- <pre>{{ form }}</pre> -->
    </b-modal>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import mediumZoom from 'medium-zoom'

export default {
  data() {
    return {
      tabs: [
        {
          key: 'pending',
          name: 'รออนุมัติ',
          component: () => import('./components/TabWithdrawPending.vue'),
          ref: 'refTablePending',
        },
        {
          key: 'approve',
          name: 'อนุมัติ',
          component: () => import('./components/TabWithdrawApprove.vue'),
          ref: 'refTableApprove',
        },
        {
          key: 'reject',
          name: 'ไม่อนุมัติ',
          ref: 'refTableReject',
          component: () => import('./components/TabWithdrawReject.vue'),
        },
      ],
      form: {},
    }
  },
  methods: {
    clickDynamic(row, keyModal = 'refModalDetailsWithdraw') {
      // this.$refs.refTablePending.getWithdrawPending()
      // console.log('clickDynamic', { row, keyModal })
      this.form = { ...row }
      this.$refs[keyModal].show()
    },
    onUploadFile(files = []) {
      if (files.length === 0) return

      files.forEach(file => {
        const newFile = file
        const blobUrl = URL.createObjectURL(file)
        newFile.preview = blobUrl
        newFile.localId = uuidv4()
        let slipImages = []

        if (this.form.slipImages) {
          slipImages = [...this.form.slipImages]
        }

        slipImages.push(newFile)

        this.form = { ...this.form, slipImages }
      })

      this.$refs.refInputUploadFile.reset()
    },
    removeFile(file) {
      const { slipImages, ...res } = this.form
      const newList = [...slipImages].filter(v => v.localId !== file.localId)
      this.form = { ...res, slipImages: newList }
    },
    async confirmWithdraw(bvEvent) {
      bvEvent.preventDefault()

      // console.log('confirmWithdraw', this.form)
      const { slipImages, transaction_data_as_id, user_as_partner_id } = this.form
      if (!slipImages || slipImages.length <= 0) {
        this.gDisplayToast('โปรดอัปโหลดหลักฐานการโอน', '', 'danger')
        return
      }
      // validate max upload 10 file
      const isConfirm = await this.gCheckConfirmV1('ยืนยันอนุมัติการโอน')
      if (!isConfirm) return

      this.$refs.refModalApproveWithdraw.hide()
      this.gOpenPageLoading()
      const formRequest = new FormData()
      formRequest.append('partner_id', user_as_partner_id)
      formRequest.append('action', 'approve')
      formRequest.append('transaction_id', transaction_data_as_id)

      for (let index = 0; index < slipImages.length; index += 1) {
        const file = slipImages[index]
        formRequest.append('files[]', file)
      }

      const resp = await this.api.post('api/admin/partner-withdraw/action', formRequest).catch(() => null)

      // console.log('confirmWithdraw', resp)

      this.gClosePageLoading()
      this.$refs.refTablePending[0].getWithdrawPending()
      if (!resp) return

      if (resp.message) {
        this.gDisplayToast('พบข้อผิดพลาด', resp.message, 'danger')
      } else {
        this.gDisplayToast('ดำเนินการสำเร็จ')
      }
    },
    async rejectWithdraw(bvEvent) {
      bvEvent.preventDefault()
      // console.log('rejectWithdraw', this.form)
      const { note, transaction_data_as_id, user_as_partner_id } = this.form
      if (!note) {
        this.gDisplayToast('โปรดระบุเหตุผล', '', 'danger')
        return
      }
      const isConfirm = await this.gCheckConfirmV1('ยืนยันไม่อนุมัติการถอน')
      if (!isConfirm) return

      this.$refs.refModalRejectWithdraw.hide()
      this.gOpenPageLoading()

      const requestData = {
        partner_id: user_as_partner_id,
        action: 'reject',
        transaction_id: transaction_data_as_id,
        note,
      }
      const resp = await this.api.post('api/admin/partner-withdraw/action', requestData).catch(() => null)
      // console.log('rejectWithdraw', resp)
      this.gClosePageLoading()
      this.gClosePageLoading()
      this.$refs.refTablePending[0].getWithdrawPending()
      if (!resp) return

      if (resp.message) {
        this.gDisplayToast('พบข้อผิดพลาด', resp.message, 'danger')
      } else {
        this.gDisplayToast('ดำเนินการสำเร็จ')
      }
    },
    zoomImage(ev) {
      const zoom = mediumZoom({ background: '#191f32', margin: 48 })
      zoom.attach(ev.target)
      zoom.on('closed', () => zoom.detach(), { once: true })
    },
  },
}
</script>

<style lang="scss" scoped></style>
